import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolboxService {

  constructor() { }

  eatCake(them) {
    return console.log(`Let ${them} eat cake.`)
  }


  ///send an array of {id:1,text:"some text"}
  getOptionListID(options: any, text: string) {

    let subset = options.filter(f => f.text === text);
    if (subset.length > 0) {
      return subset[0].id;
    } else {
      return 0;
    }
  }

  //validates a date is actually a date
  isDate(d) {
    if (!d) return false;
    if (d === '1/1/1900 12:00:00 AM') return false;
    let date = new Date(d);
    return date instanceof Date && !isNaN(date.valueOf());
  }

  shortDate(stringValue) {
    let cellValue = stringValue


    var cellDate: Date;
    var dateNumber = Date.parse(cellValue);
    if (isNaN(dateNumber)) {
      cellDate = new Date(1, 1, 2000);
      return ''
    } else {
      cellDate = new Date(cellValue);
    }
   // cellDate.setMinutes(cellDate.getMinutes() + 1);

    if (cellValue.length > 0 && cellValue.length <= 5) {
      const seperator = (cellValue.match(/[/|-]/g) || []).length;
      if (cellDate.getFullYear() === 2001 && seperator == 1) cellDate.setFullYear(new Date().getFullYear());
    }

    //console.log('test short date')
    let retval = (cellDate.getMonth() + 1) + '/' + cellDate.getDate() + '/' + cellDate.getFullYear();
    if (cellDate.getFullYear() === 1900) retval = '';
    return retval;
  }

  dateFixer(params) {
    if (!params.data) {
      return null
    } else {
      let v = params.data[params.colDef.field];
      if (v === '') return '';
      if (v == 'NA' || v == 'N/A' || v == 'na') return 'NA'
      //return  this.shortDate(params);
      return this.shortDate(v);
    }
  }


  datesInGrid(params) {
    //debugger
    if (!params.data) return null;
    
    const dateValue = new Date(params.value);
    
    // Check for invalid dates
    if (isNaN(dateValue.getTime())) return params.value; 

    const checkDate = dateValue.toISOString().split('T')[0];
    
    if (checkDate === '1800-01-01') {
        return 'NEEDED';
    } else if (checkDate === '1900-01-01') {
        return 'N/A';
    } else {
        return dateValue.toLocaleDateString(); 
    }
  }

  naFormatter(params: any, fieldName: string): string | null {
    const fieldValue = params.data?.[fieldName];

    if (!fieldValue) return null;

    const dateValue = new Date(fieldValue);

    if (dateValue.toLocaleDateString() === '1/1/1800') {
      return 'NEEDED';
    } else if (dateValue.toLocaleDateString() === '1/1/1900') {
        return 'N/A';
    } else if (!isNaN(dateValue.getTime())) {
        // If valid date, return formatted date
        return dateValue.toLocaleDateString();
    } else if (fieldValue === 'N/A' || fieldValue === 'n/a') {
      return 'N/A';
  } else {
      if (fieldValue === 'NEEDED' || fieldValue === 'needed') {
          return 'NEEDED';
      }
  }

}

  dateDiff(startDate: Date, endDate: Date, DaysMinutes: string = 'D') {
    var now = new Date();
    var t2 = startDate.getTime();
    var t1 = endDate.getTime();
    let diff = ((t1 - t2) / (24 * 3600 * 1000));
    return diff
  }
  
  //Reformats date string to YYYYMMDD. Primarily for Google Caledar.
  dateGetter(dateStr: string) {
    let myDate = new Date()

    if (dateStr != "") {
      myDate = new Date(dateStr)
    }

    let dd: string;
    let myMonth = myDate.getMonth() + 1;
    var mm;

    let myDay = myDate.getDate();

    if (myDay < 10) {
      dd = '0' + myDay;
    } else {
      dd = myDay.toString();
    }

    if (myMonth < 10) {
      mm = '0' + myMonth;
    } else {
      mm = myMonth.toString();
    }



    let yyyy = myDate.getFullYear().toString();
    let retval = yyyy + mm + dd
    return retval
  }

  paddy(num, padlen, padchar) {
    var pad_char = typeof padchar !== 'undefined' ? padchar : '0';
    var pad = new Array(1 + padlen).join(pad_char);
    return (pad + num).slice(-pad.length);
  }

  //HTML special character coverter
  parseHTMLChar(val) {
    if (typeof val === 'string' || val instanceof String) {
      //val = val.replace('%', '%25')
      //val = val.replace('#', '&#35')
      // val = val.replace('&', '&amp')
      //val = val.replace('+', '%2B')
    }
    return val

  }

  sqlToJsDate(sqlDate) {
    //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
    let sqlDateArr1: any = sqlDate.split("-");
    //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
    let sYear: number = sqlDateArr1[0];
    let sMonth: string = (Number(sqlDateArr1[1]) - 1).toString();
    let sqlDateArr2: any = sqlDateArr1[2].split("T");
    //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
    let sDay: number = sqlDateArr2[0];
    let sqlDateArr3: any = sqlDateArr2[1].split(":");
    //format of sqlDateArr3[] = ['hh','mm','ss.ms']
    let sHour: number = sqlDateArr3[0];
    let sMinute: number = sqlDateArr3[1];
    let sqlDateArr4: any = sqlDateArr3[2].split(".");
    //format of sqlDateArr4[] = ['ss','ms']
    let sSecond: number = sqlDateArr4[0];
    let sMillisecond: number = sqlDateArr4[1];

    return new Date(sYear, parseInt(sMonth), sDay, sHour, sMinute, sSecond);
  }

  formatDate(d) {
    function addLeadingZero(n) { return n < 10 ? '0' + n : '' + n }
    var year = d.getFullYear();
    var month = addLeadingZero(d.getMonth());
    var day = addLeadingZero(d.getDay());
    var hours = d.getHours();
    var minutes = d.getMinutes();

    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes
  }

  formatJsToSqlDate(jsDate: Date) {
    var dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    var timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
    var d = jsDate.toLocaleDateString('en-US', dateOptions)
    var t = jsDate.toLocaleTimeString('en-US', timeOptions)
    var retval = d + ' ' + t
    return retval;
  }


  invoiceCurrencyFormatter(value: any) {
    if (value === undefined || value === null) {
      return '';
    }
  
    let stringValue = value.toString();
    let remove$ = stringValue.replace('$', '');
    let format$ = remove$.replaceAll(',', '');
  
    const number = format$;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    const currencyFormatted = formatter.format(number);
    return currencyFormatted;
  }

  // Converts a number to currency
  currencyFormatter(params) {
    if (!params.value) return null;
    var money;
    if (params.value) {
      let myval = parseFloat(params.value);
      money = myval.toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"); //somehow this adds a comma in the correct place in the number format.

    } else {
      money = params.value;
    }
    let retval = '$' + money;
    //console.log("currencyFormatter retval => ", retval)
    return retval;
  }

  dateComparator(date1, date2) {
    var date1Number = Date.parse(date1);
    var date2Number = Date.parse(date2);
    if (isNaN(date1Number)) { date1Number = 0; }
    if (isNaN(date2Number)) { date2Number = 0; }
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;

  }

  dateFilterComparator(filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return 0;
    var cellDate: Date;
    var dateNumber = Date.parse(dateAsString);
    if (isNaN(dateNumber)) {
      cellDate = new Date(1, 1, 2000);
    } else {
      cellDate = new Date(cellValue)
    }
    // Now  we can compare
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    } else {
      return 0;
    }
  }

}
