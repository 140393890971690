import { Component, OnInit, Injectable } from '@angular/core';
import { ChatterService } from './shared/services/chatter.service';
import { HubConnection } from '@microsoft/signalr';
import { ChatRecord } from './shared/model/models';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  providers: []
})
export class ChatComponent implements OnInit {
  private _hubConnection: HubConnection | undefined;
  public async: any;
  message = '';
  messages: string[] = [];
  msgs: any;
  public name: string = '';
   

  constructor(private chatter: ChatterService) {

    // this.name = new Date().toString();
  }

  public sendMessage(): void {



    const data = `${this.message}`;
    if (data === '') return;
    let chatRecord = new ChatRecord("All", [], data, this.name );
    this.chatter.post(chatRecord);
    //this._hubConnection.invoke('Send', this.name, JSON.stringify(chatRecord));
    this.message = '';
  }

  ngOnInit() {

    this._hubConnection = this.chatter.hubConnection;
    this.chatter.messageStream.subscribe(retval => {
      console.log(retval);
      this.messages.push(retval);
      var element = document.getElementById("scroller");
      element.scrollTop = element.scrollHeight;
    })

  }



}