import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';


import { AppRoutingModule, rootRouterConfig } from './app.routes';
import { AppCommonModule } from "./components/common/app-common.module";
import { AppComponent } from './app.component';


// import { DragDropModule } from '@angular/cdk/drag-drop';
// import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';



import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
 




import { ToastrModule } from 'ngx-toastr';






import { RoutePartsService } from './services/route-parts/route-parts.service';
import { NavigationService } from "./services/navigation/navigation.service";


import { AuthService } from './services/auth/auth.service';
import { SharedServicesModule } from './services/shared-services.module';
import { SiteReviewService } from './services/site-review.service';
import { SmartSheetService } from './services/smart-sheet-service';
 
import { TestComponent } from './test/test.component';
  
// import { GoogleChartsBaseService } from './services/google-charts-base.service'

// import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { FileMenuComponent } from './views/site-review/bid-details/file-menu';
import { ChatModule } from './chat/chat.module';
import { ChatterService } from './chat/shared/services/chatter.service';

import { NgxPubSubModule } from '@pscoped/ngx-pub-sub';
// import { FleetService } from './views/equipment/fleet.service';
//import { ImageService } from './services/image.service';
import { AppConfigService } from './services/app-config.service'
// import { CostEstimatorComponent } from './views/finance/cost-estimator/cost-estimator.component';
import { QuillModule } from 'ngx-quill';
import { RouterModule } from '@angular/router';
  
    
import { AgGridHelperService } from './services/ag-grid-helper.service';
 
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
export function appInit(appConfigService: AppConfigService) {
  return () => {
    console.log('initializing System in AppConfigService');
    const ret = appConfigService.load();    
    return ret;
  }
}

@NgModule({
  imports: [
    BrowserModule,
    QuillModule.forRoot(),
    //CurrencyMaskModule,
    // DragDropModule, 
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    //A11yModule,
    //  PortalModule,
 
    AppCommonModule,
    SharedServicesModule, 
    AppRoutingModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   }
    // }),
    ChatModule,
    NgxPubSubModule,
     RouterModule.forRoot(rootRouterConfig, { useHash: false })
  ],
  declarations: [AppComponent, TestComponent,   FileMenuComponent],
  entryComponents: [FileMenuComponent],
  exports: [
    BrowserModule,
    AppRoutingModule,    
    BrowserAnimationsModule,
    AppCommonModule
  ],
  providers: [
    RoutePartsService,
    NavigationService,
    ChatterService,
    
    AuthService,
    SiteReviewService,
    SmartSheetService,
    AgGridHelperService,
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AppConfigService]
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }