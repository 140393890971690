import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


export const pdfServer = environment.serverURL;     
export const apiServer = environment.siteReviewURL; 


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  sessionId="unassigned-CommonService";
  private sessionIdKey = 'sr_session_id';
  public pdfServer=pdfServer;
  public apiServer=apiServer;
  constructor(private _http: HttpClient) {
    this.sessionId = localStorage.getItem(this.sessionIdKey);   
  }


  command(commandObject: any): Observable<any> {
    let url: string = apiServer + '/api/cmd?tracker=' + commandObject.procedure + '&sid=' + this.sessionId;
    return this._http.post(url, commandObject)
      .pipe(map((res: any) => res));
  }

  async commandAsync(commandObject: any): Promise<any> {
    let url: string = apiServer + '/api/cmd?tracker=' + commandObject.procedure + '&sid=' + this.sessionId;
    return this._http.post(url, commandObject)
      .pipe(map((res: any) => res)).toPromise();
   }


}
