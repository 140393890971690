import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from './user.service';
@Injectable()
export class UsersResolve implements Resolve<any> {

    constructor(
      private userService: UserService, 
      private router: Router
    ) { }

    resolve() {
      return this.userService.getAll();
    }
}