<h2 mat-dialog-title>{{this.data?.title}}</h2>
<mat-dialog-content>

    <div *ngFor="let r of results" class="tbl">
        <div style="padding:5px;border: solid 1px lightgray;" class="row-item">
            <!-- {{r.previousValue}} <i class="material-icons" inline="true">  chevron_right </i> {{r.newValue}} -->
            {{r.newValue}}
            <div>{{r.updatedDate}} by {{r.email}}</div>
        </div>
    </div>

    <div *ngIf="results?.length==0">
        <h4>There has been no change to this value</h4>
    </div>
</mat-dialog-content>
<mat-dialog-actions> 
    <button slot="right" class="mat-raised-button" [mat-dialog-close]="true">Close</button>
 </mat-dialog-actions>