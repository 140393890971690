
declare var google: any

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PieChartConfig } from 'app/models/pie-chart-config';

@Component({
  selector: 'app-pie-chart',
  template: `<div #container ></div>`
})
export class PieChartComponent implements OnInit {
  @Input() config: PieChartConfig;

  private _data;

  @Input()
  set data(value: any) {
    this._data = value;
    this.loadChart();
  }
  get data() {
    return this._data;
  };

  @ViewChild('container') container: ElementRef;




  constructor() {   google.charts.load('current', { 'packages': ['corechart'] });
}

  ngOnInit(): void {
    //have some overloads and defaults
    if (!this.data) this.data = [{ "Quantity": 123, "Topping": "Mushroom" }, { "Quantity": 456, "Topping": "Pepperoni" }, { "Quantity": 789, "Topping": "Sausage" }]

    if (!this.config) this.config = { title: "This is the default Title", pieHole: .02 };

    this.loadChart()

  }

  loadChart() {
    var callback = () => this.drawChart();
    google.charts.setOnLoadCallback(callback);
  }

  exampleDataStructures() {
    // var data = new google.visualization.DataTable();

    // data.addColumn('string', 'Topping');
    // data.addColumn('number', 'Slices');
    // data.addRows([
    //   ['Mushrooms',2],
    //   ['Onions', 1],
    //   ['Olives', 1],
    //   ['Zucchini', 1],
    //   ['Pepperoni', 2]
    // ]);

    // var data = google.visualization.arrayToDataTable([
    //   ['Topping', 'Slices'],
    //   ['Mushrooms', 2],
    //   ['Onions', 1],
    //   ['Olives', 1],

    //   ['Zucchini', 1],
    //   ['Pepperoni', 2]
    // ]);



    // var options = { title: this.title, pieHole: this.pieHole };
    // var chart = new google.visualization.PieChart(this.container.nativeElement);
    // chart.draw(data, options);

  }


  drawChart() {

    let chartData = this.convertToArray(this.data);
    var chartFunc = this.BuildPieChartObject(this.config);
    this.buildChart(chartData, chartFunc, this.config);
  }


  convertToArray(objArray): Array<any> {
    console.log('Working out array')
    let myArray = [['Title', 'Name']];


    var prop: Array<any> = Object.getOwnPropertyNames(objArray[0]);

    var data = prop[0];
    var title = prop[1];

    console.log("key, data", title, data);
    objArray.forEach(element => {

      myArray.push([element[title], element[data]])
    });
    return myArray;



  }

  public BuildPieChartObject(config: PieChartConfig): Function {
    var chartFunc = () => { return new google.visualization.PieChart(this.container.nativeElement); };
    var options = {
      title: config.title,
      pieHole: config.pieHole,
    };

    return chartFunc;
  }



  public buildChart(data: any[], chartFunc: any, options: any): any {
    var func = (chartFunc, options) => {
      var datatable = google.visualization.arrayToDataTable(data);
      chartFunc().draw(datatable, options);
    };
    var callback = () => func(chartFunc, options);
    google.charts.setOnLoadCallback(callback);
  }





}
