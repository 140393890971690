 <div class="head">
   Field History
  <!--{{history.column  }}-->
</div>
<div style="width:200px;background-color: #3F51B5">&nbsp;</div>
<div *ngFor = "let r of results" class="tbl">
  <div style="padding:5px;border: solid 1px lightgray;" class="row-item">
 <!-- {{r.previousValue}} <i class="material-icons" inline="true">  chevron_right </i> {{r.newValue}} -->
  {{r.newValue}}
  <div>{{r.updatedDate}} by {{r.email}}</div> 
</div>
</div>
