import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Command } from 'app/models/command';
import { FleetService } from 'app/views/equipment/fleet.service';

@Component({
  selector: 'app-history-row-dialog',
  templateUrl: './history-row-dialog.component.html',
  styleUrls: ['./history-row-dialog.component.css']
})
export class HistoryRowDialogComponent implements OnInit {
  history;
  results = [];

  constructor(private db: FleetService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
      console.log('History Row Dialog', data);
  }

  ngOnInit(): void {
     this.retrieveHistory(this.data)
  }

  retrieveHistory(obj) {
   
    let cmd = new Command();
    cmd.procedure = "cmdGetRowHistory"
    cmd.addParameter("TableName", obj.table)
    cmd.addParameter("KeyValue", obj.id)

    this.db.command(cmd)
      .subscribe(retval => {
        
        retval.forEach(res => {
         res.columnName =  this.titleCase(res.columnName);
         if(res.newValue === res.previousValue || res.columnName === '' || res.columnName.toLowerCase() === 'html'){
          return
         } else {
          this.results.push(res);
         }
        })
        //this.results = retval;
        console.log('results', retval)

      });
  }

  titleCase(string){
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }
 
  btnCancel(){
    this.dialogRef.close();
  }
}
