import { Injectable } from '@angular/core';
import { Command } from 'app/models/command';
import { SiteReviewService } from 'app/services/site-review.service';
import { HttpClient } from '@angular/common/http';
import { ObservableStore } from '@codewithdan/observable-store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToolboxService } from 'app/toolbox.service';
import { environment } from "../../../environments/environment";
import { DataStoreService } from 'app/services/datastore.service';
import { Vehicle } from 'app/models/fleet/vehicle';
import { StockLocation } from 'app/models/fleet/stock-location';
import { EquipmentCategory, Foreman, RepairOrderActions, StoreState } from 'app/interfaces/store-state';
import { RepairOrder } from 'app/models/fleet/repair-order';
import { LbsEntryComponent } from './lbs-entry/lbs-entry.component';
import { ILbsReport } from 'app/interfaces/lbs';


export const apiServer = environment.siteReviewURL;// "https://sitereviewapi.azurewebsites.net";

@Injectable({
  providedIn: 'root'
})
export class FleetService extends ObservableStore<StoreState> {

  apiServer;
  sessionId;
  vinSearchKey = 'CLJmNLoD1780Bakf14D3C81p387FcpuAKlO';
  private equipmentTypes = ['Bucket', 'Squirt Boom', 'Digger/Line', 'Forklift', 'Splice Van', 'Flatbed', 'Service Truck', 'Tow Behind']
  private categories = [
    { category: 'Passenger', image: 'assets/fleet/imgs/passenger.jpg', title: 'Add a New Passenger Vehicle', description: 'Site Reviews and Pre-Flights', subCategories: [{ title: 'A', value: 'A' }, { title: 'B', value: 'B' }] },
    { category: 'Bucket', image: 'assets/fleet/imgs/bucket.jpg', title: 'Add a New Bucket Truck', description: 'Vehicles and Inspections', subCategories: [{ title: 'C', value: 'C' }, { title: 'D', value: 'D' }] },
    { category: 'Trailer', image: 'assets/fleet/imgs/trailer.jpg', title: 'Add a New Trailer', description: 'Open up google drive to review files', subCategories: [{ title: 'E', value: 'E' }, { title: 'F', value: 'F' }] },
    { category: 'Digger', image: 'assets/fleet/imgs/digger.jpg', title: 'Add a New Digger', description: 'Enter all your time sheets here', subCategories: [{ title: 'G', value: 'G' }, { title: 'H', value: 'H' }] },
    { category: 'Van', image: 'assets/fleet/imgs/van.jpg', title: 'Add a New Van', description: 'Open up google drive to review files', subCategories: [{ title: 'I', value: 'I' }] },
    { category: 'UTV', image: 'assets/fleet/imgs/utv.jpg', title: 'Ad a new UTV', description: 'Enter all your time sheets here', subCategories: [{ title: 'J', value: 'J' }, { title: 'K', value: 'K' }] }
  ];

  private foremen = [];
  private equipmentOwners = []

  pentaApiServer = environment.serverURL;


  equipmentFuelTypes = ['Diesel', 'Gas', 'NA'];


  constructor(private _http: HttpClient, private dataStore: DataStoreService, private toolbox: ToolboxService) {
    super({ trackStateHistory: true, logStateChanges: true });
    this.apiServer = apiServer;
    this.sessionId = localStorage.getItem("sr_session_id");

  }

  async getVehicleRepairOrders(id: number = 0): Promise<RepairOrder[]> {
    if (id > 0) {
      var cmd: Command = new Command();
      cmd.procedure = 'cmdGetRepairOrders';
      cmd.addParameter('RepairOrderID', id);

      return this.command(cmd).pipe
        (map(repairOrders => {

          return repairOrders;
        })).toPromise();

    }

    /* 
    if (state && state.repairOrders) {
      return state.repairOrders;
    }
    */
  }


  //TODO: This should use the state for both the list and the request by id
  async getRepairOrders(id: number = 0): Promise<RepairOrder[]> {
    const state = this.getState();

    if (id > 0) {
      var cmd: Command = new Command();
      cmd.procedure = 'cmdGetRepairOrders';
      cmd.addParameter('RepairOrderID', id);

      return this.command(cmd).pipe
        (map(repairOrders => {

          return repairOrders;
        })).toPromise();

    }
    if (state && state.repairOrders) {
      return state.repairOrders;
    }

    var cmd: Command = new Command();
    cmd.procedure = 'cmdGetRepairOrders';
    return this.command(cmd).pipe
      (map(repairOrders => {
        this.setState({ repairOrders }, RepairOrderActions.GetRepairOrder)
        return repairOrders;
      })).toPromise();
  }

  async updateRepairOrderState(repairOrder: RepairOrder) {
    const state = this.getState();
    if (state && state.repairOrders) {
      let repairOrders = state.repairOrders.filter(v => v.repairOrderID != repairOrder.repairOrderID);
      repairOrders.push(repairOrder);
      this.setState({ repairOrders }, EquipmentCategoryStoreActions.UpdateEquipmentCategory)
    }
  }

  async addRepairOrder(stockID, reportedBy, reasonForService, data = null) {

    /*[cmdAddRepairOrder](@StockID INT, @ReportedBy VARCHAR(50), @ReasonForService VARCHAR(500),@SessionID VARCHAR(20)*/
    const state = this.getState();
    if (state && state.repairOrders) {
      state.repairOrders = null;
    }
    var cmd: Command = new Command();
    cmd.procedure = 'cmdAddRepairOrder';
    cmd.addParameter('StockID', stockID);
    cmd.addParameter('ReportedBy', reportedBy);
    cmd.addParameter('ReasonForService', reasonForService)
    return this.command(cmd).pipe
      (map(async repairOrder => {

        return repairOrder[0];
      })).toPromise();

  }


  async addVechicleRepairOrders(stockID, reportedBy, reasonForService, vehicleID) {
    var cmd: Command = new Command();
    cmd.procedure = 'cmdAddVehicleRepairOrder';
    cmd.addParameter('StockID', stockID);
    cmd.addParameter('vehicleID', vehicleID);
    cmd.addParameter('ReportedBy', reportedBy);
    cmd.addParameter('ReasonForService', reasonForService);
    return this.command(cmd).pipe
      (map(repairOrder => {
        //console.log("repairOrder from service => ", repairOrder);
        return repairOrder[0];
      })).toPromise();
  }


  async getVehicleRepairOrdersWithID(rID, stockID?): Promise<RepairOrder[]> {
    var cmd: Command = new Command();
    cmd.procedure = 'cmdGetVechicleRepairOrders';
    cmd.addParameter('RepairOrderID', rID);
    if (stockID != null) cmd.addParameter('StockID', stockID);

    return this.command(cmd).pipe
      (map(repairOrders => {
        return repairOrders;
      })).toPromise();
  }

  async getVendorList(category) {

    const state = this.getState();
    if (state && state.vendors) return state.vendors;

    var cmd: Command = new Command();
    cmd.procedure = 'cmdVendorList';
    cmd.addParameter('Category', category);

    return this.command(cmd).pipe
      (map(vendors => {
        this.setState({ vendors }, VehicleStoreActions.GetVehicles)
        return vendors;
      })).toPromise();
  }



  //deprecated
  async postGoogleRepairOrder_OLD(ro, data) {

    let url = 'https://script.google.com/a/macros/pinnaclepowersvcs.com/s/AKfycbykiL5D5OoVAyan9tMHOz_txEDHLVNmBr08dEytc950iqPQLsF7CKXR/exec?';
    let roNumber = ro.repairOrderID + '-' + data.stockID;
    let truck = data.make + ' ' + data.model;
    let pps = data.newPPS;
    let truckType = data.equipmentType;
    let reportDate = (new Date()).toLocaleDateString('en-US');
    let params = `RO=${roNumber}&Truck=${pps}&ReportDate=${reportDate}&ReportBy=${ro.reportedBy}&TruckType=${truckType}&Reason=${ro.reasonForService}`

    let obj = { url: url + params }
    url = url + params;
    console.log('url to paste to google sheet', url);
    return this._http.post(this.apiServer + "/api/GoogleWebApp?key=CLJmNLoD1780Bakf14D3C81p387FcpuAKlO", obj).toPromise();

  }

  async postGoogleRepairOrder(ro, data) {

    let url = `${this.apiServer}/api/google/master-repair?sid=${this.sessionId}`;
    let roNumber = ro.repairOrderID + '-' + data.stockID;
    let truck = data.make + ' ' + data.model;
    let pps = data.newPPS;
    //let truckType = data.equipmentType;
    let truckType = (data.equipmentType !== undefined && data.equipmentType !== null && data.equipmentType !== '') 
                ? data.equipmentType 
                : (data.truckType || '');
    let reportDate = (new Date()).toLocaleDateString('en-US');
    let params = `&RO=${roNumber}&Truck=${pps}&ReportDate=${reportDate}&ReportBy=${ro.reportedBy}&TruckType=${truckType}&Reason=${ro.reasonForService}`

    let obj = { url: url + params }
    url = url + params;
     

    console.log('url to paste to google sheet', url);
    return this._http.post(url, ro).toPromise();

  }





  async getEquipmentCategories(): Promise<EquipmentCategory[]> {
    const state = this.getState();
    if (state && state.equipmentCategories) return state.equipmentCategories;
    var cmd: Command = new Command();
    cmd.procedure = 'cmdEquipmentTypeList';
    return this.command(cmd).pipe
      (map(equipmentCategories => {
        this.setState({ equipmentCategories }, EquipmentCategoryStoreActions.GetEquipmentCategory)
        return equipmentCategories;
      })).toPromise();
  }

  async getOptions(optionName: string) {
    let cmd = new Command();
    cmd.procedure = 'cmdOptionList';
    cmd.addParameter('OptionName', optionName)
    return this.command(cmd).toPromise();
  }

  updateEquipmentCategoryState(equipmentCategory: EquipmentCategory) {
    const state = this.getState();
    if (state && state.equipmentCategories) {
      let equipmentCategories = state.equipmentCategories.filter(v => v.equipmentTypeID != equipmentCategory.equipmentTypeID);
      equipmentCategories.push(equipmentCategory);
      this.setState({ equipmentCategories }, EquipmentCategoryStoreActions.UpdateEquipmentCategory)
    }
  }

  async getVehicles(forceRefresh = false): Promise<any> {
    const state = this.getState();
    if (state && state.vehicles && !forceRefresh) return state.vehicles;

    var cmd: Command = new Command();
    cmd.procedure = 'cmdVehicleList';
    return this.command(cmd).pipe
      (map(vehicles => {
        this.setState({ vehicles }, VehicleStoreActions.GetVehicles)
        return vehicles;
      })).toPromise();
  }


  updateVehicleState(vehicle: Vehicle) {
    const state = this.getState();
    if (state && state.vehicles) {
      let vehicles = state.vehicles.filter(v => v.vehicleID != vehicle.vehicleID);
      vehicles.push(vehicle);
      this.setState({ vehicles }, VehicleStoreActions.UpdateVehicle)
    }
  }
  // updateRepairOrderState(repairOrder:RepairOrder){

  //   const state = this.getState();
  //   if (state && state.repairOrders) {
  //     let recs = state.repairOrders.filter(v => v.repairOrderID != v.repairOrderID);
  //     recs.push(repairOrder);
  //     this.setState({ recs }, RepairOrderActions.UpdateRepairOrder)
  //   }

  // }
  async getEquipmentOwners(): Promise<any> {
    return new Promise((resolve, reject) => resolve(this.equipmentOwners));
  }

  
  async getStockLocations(): Promise<any> {
    let cmd = new Command();
    cmd.procedure = 'cmdStockLocations';
    return this.command(cmd).toPromise();


  }

  async getForemen(type: 'All' | 'General' | 'Simple'): Promise<any> {

    const state = this.getState();
    if (state && state.foreman) {
      let fm = await state.foreman;
      return fm;
    }
    var foremanOptions: Foreman[];
    var generalForemanOptions: Foreman[];

    var cmd: Command = new Command();
    cmd.procedure = 'cmdVehicleList';
    let url: string = this.apiServer + '/api/cmd?sid=' + this.sessionId + '&tracker=getForemen';;
    let cmd1 = new Command();
    cmd1.procedure = 'cmdLookupTable';
    cmd1.addParameter('LookupName', 'Foreman');
    foremanOptions = await this._http.post(url, cmd1).pipe(map((retval: any) => {
      retval.forEach(f => {
        f.type = 'foreman';
      });
      return retval;
    }
    )).toPromise();

    let cmd2 = new Command();
    cmd2.procedure = 'cmdLookupTable';
    cmd2.addParameter('LookupName', 'GeneralForeman');
    generalForemanOptions = await this._http.post(url, cmd2).pipe(map((retval: any) => {
      retval.forEach(f => {
        f.type = 'generalForeman';
      });

      return retval;
    })).toPromise();

    foremanOptions.concat(generalForemanOptions)

    return new Promise((resolve, reject) => resolve(foremanOptions));

  }

  async getLocationByVin(vin: string): Promise<any> {
    return this._http.get(`${this.apiServer}/api/Zonar/${vin}`).toPromise();
  }
  async getEquipmentTypes(): Promise<any> {
    this.equipmentTypes = [];
    this.categories.forEach(type => {
      this.equipmentTypes.push(type.category);
    });

    return new Promise((resolve, reject) => resolve(this.equipmentTypes));
  }
  async getStockFiles(stockID: number) {
    let cmd = new Command();
    cmd.procedure = "cmdGetStockFiles";
    cmd.addParameter("StockID", stockID)
    return this.command(cmd).toPromise();
  }
  async addStockLog(stockID: number, note: string) {
    let cmd = new Command();
    cmd.procedure = "cmdAddStockLog";
    cmd.addParameter("StockID", stockID)
    cmd.addParameter("Comment", note)
    return this.command(cmd).toPromise();

  }
  async getStockLog(stockID: number) {
    let cmd = new Command();
    cmd.procedure = "cmdGetStockLog";
    cmd.addParameter("StockID", stockID)

    return this.command(cmd).toPromise();

  }

  getPMList() {
    let cmd = new Command();
    cmd.procedure = 'cmdInspectorReport';
    cmd.addParameter('PastDays', 10);
    cmd.addParameter('UpcomingDays', 14);
    return this.command(cmd);
  }

  async getInspections(vehicleID: number): Promise<any> {
    
    let url = `${this.apiServer}/api/fleet/vehicle/${vehicleID}/inspections?sid=${this.sessionId}`;
    return this._http.get(url).toPromise();
  
  }
  async saveStockLog(stockLogID, comment, status) {
    let cmd = new Command();
    cmd.procedure = "cmdUpdateStockLog";
    cmd.addParameter("StockLogID", stockLogID)
    cmd.addParameter("Comment", comment)
    cmd.addParameter("Status", status)
    return this.command(cmd).toPromise();

  }
  async saveStockFile(stockLogID, status) {
    let cmd = new Command();
    cmd.procedure = "cmdDeleteStockFile";
    cmd.addParameter("StockFileID", stockLogID)
    cmd.addParameter("Status", status)
    return this.command(cmd).toPromise();

  }

  async assignVehicleLocation(vehicle: Vehicle, origin: StockLocation, destination: StockLocation) {
    //exec cmdAllocateStock @StockID =1, @Quantity =1,@OriginLocationID INT=0, @StockLocationID= 1, @SessionID ='M1D3e5sftsk4SI0bXJ2D'
    let cmd = new Command();
    cmd.procedure = 'cmdAllocateVehicle';
    cmd.addParameter('VehicleID', vehicle.vehicleID)
    cmd.addParameter('Quantity', 1);
    cmd.addParameter('OriginLocationID', origin.stockLocationID);
    cmd.addParameter('StockLocationID', destination.stockLocationID);
    return this.command(cmd).toPromise()
  }

  async lookupVin(vinNumber: string): Promise<any> {
    vinNumber = vinNumber.trim();
    let url = `${this.apiServer}/api/vinlookup?vin=${vinNumber}&key=${this.vinSearchKey}`;
    //let url = `${this.apiServer}/api/vinlookup?vin=1FVDCXDT5EHFP3966&key=CLJmNLoD1780Bakf14D3C81p387FcpuAKlO`;
    return this._http.get(url).toPromise();

  }
  async getEquipmentYearOptions(): Promise<any> {

    var start: number = new Date().getFullYear() + 2;
    var years = []
    var i = 0;  //how many years back are we going to allow
    while (i <= 20) {
      years.push(start);
      start = start - 1;
      i++
    }
    return new Promise(resolve => resolve(years));

  }


  async newVehicle(v) {

    var cmd = new Command();
    cmd.procedure = 'cmdNewVehicle';
    cmd.addParameter('Vin', v.vin)
    cmd.addParameter('Make', v.make)
    cmd.addParameter('Model', v.model)
    cmd.addParameter('Year', v.year)
    cmd.addParameter('FuelType', v.fuelType)
    cmd.addParameter('EquipmentType', v.equipmentType)
    cmd.addParameter('Owner', v.owner)
    cmd.addParameter('LicensePlate', v.licensePlate)
    cmd.addParameter('NextInspection', this.calculateNextInspection())
    return await this.command(cmd)
      .pipe(
        map(res => {
          var vehicle;
          if (res.len > 0) {
            vehicle = res[0];
          } else {
            vehicle = res;
          }
          const state = this.getState();
          if (state && state.vehicles) {
            let vehicles = state.vehicles;
            vehicles.unshift(vehicle);
            this.setState({ vehicles }, VehicleStoreActions.AddVehicle)
          }
          return res;
        })
      )
      .toPromise()

  }

  calculateNextInspection() {
    var d = new Date();
    var ts = d.getTime();
    var add90days = ts + (90 * 24 * 60 * 60 * 1000);
    return new Date(add90days).toLocaleDateString("en-US")
  }

  getROImages(id: number): Promise<any> {


    let url = `${this.apiServer}/api/fleet/repairs/${id}/image?sid=${this.sessionId}`;
    return this._http.get(url).pipe(map((images: any) => {
      images.forEach(img => {
        img.createdDate = this.toolbox.formatDate(this.toolbox.sqlToJsDate(img.createdDate));
      });
      return images;
    })).toPromise();


  }
  async deleteROImage(repairOrderID: number, repairOrderPhotoID: number) {
    // [Route("api/fleet/vehicle/{VehicleID}/image/{id}")]

    let url = `${this.apiServer}/api/fleet/repairs/${repairOrderID}/image/${repairOrderPhotoID}?sortorder=1&sid=${this.sessionId}`;
    console.log('delete')
    return this._http.delete(url).toPromise();

  }
  getVehicleImages(vehicleID: number): Observable<any> {
    let url = `${this.apiServer}/api/fleet/vehicle/${vehicleID}/image?sid=${this.sessionId}`;
    return this._http.get(url).pipe(map((images: any) => {
      images.forEach(img => {
        //img.createdDate = this.toolbox.formatDate(this.toolbox.sqlToJsDate(img.createdDate));
        img.createdDate = new Date(img.createdDate).toLocaleString()
      });
      return images;
    }));
  }

  setvehicleImageAsCover(vehicleID, vehiclePhotoID) {
    //        [Route("api/fleet/vehicle/{VehicleID}/image/{VehiclePhotoID}")]
    let url = `${this.apiServer}/api/fleet/vehicle/${vehicleID}/image/${vehiclePhotoID}?sid=${this.sessionId}`;
    return this._http.put(url, null);

  }
  deleteVehicleImage(vehicleID: number, vehiclePhotoID: number) {
    // [Route("api/fleet/vehicle/{VehicleID}/image/{id}")]
    let url = `${this.apiServer}/api/fleet/vehicle/${vehicleID}/image/${vehiclePhotoID}?sortorder=1&sid=${this.sessionId}`;
    return this._http.delete(url);

  }
  command(commandObject: any): Observable<any> {
    //    return await this.command(cmd).toPromise()

    let url: string = this.apiServer + '/api/cmd?tracker=' + commandObject.procedure + '&sid=' + this.sessionId;
    return this._http.post(url, commandObject)
      .pipe(map((res: any) => res));
    //  .do(x => console.log(x));
  }


 /**
  * Sends a post to Morrisdev Slack
  * @param msg 
  * @param error 
  */

  slacker(msg: string, error: string = 'none'): void {

    let url = this.apiServer + '/api/logger/slack';
    let loc = window.location.href;
    let session = JSON.parse(localStorage.getItem("session"))
    let user = session.idTokenPayload
    var message = {
      "text": "Dash Logger",
      "blocks": [
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": msg
          }
        },
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": "<" + loc + "|" + loc + ">"
          }
        },
        {
          "type": "section",
          "fields": [
            {
              "type": "mrkdwn",
              "text": "*Details*\n" + error
            }
          ]
        },
        {
          "type": "section",
          "fields": [
            {
              "type": "mrkdwn",
              "text": "*User*\n" + JSON.stringify(user)
            }
          ]
        }
      ]
    }
    console.log(message);
    // var message = {"text":"Hello, World!"}
    this._http.post(url, message).subscribe();

  }

  /* ####################### LBS #################### */
  async submitLbsAsync(report: ILbsReport): Promise<any> {
    return this._http.post(`${this.apiServer}/api/lbs?sid=${this.sessionId}`,report).toPromise();
  }
}



export enum VehicleStoreActions {
  AddVehicle = 'ADD_VEHICLE',
  UpdateVehicle = 'UPDATE_VEHICLE',
  RemoveVehicle = 'REMOVE_VEHICLE',
  GetVehicles = 'GET_VEHICLE',
}




export enum EquipmentCategoryStoreActions {
  AddEquipmentCategory = 'ADD_EQUIPMENT_CATEGORY',
  UpdateEquipmentCategory = 'UPDATE_EQUIPMENT_CATEGORY',
  RemoveEquipmentCategory = 'REMOVE_EQUIPMENT_CATEGORY',
  GetEquipmentCategory = 'GET_EQUIPMENT_CATEGORY',
}
