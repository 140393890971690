<h2 mat-dialog-title>Reset Local Data</h2>
<mat-dialog-content>
    <p>
        If your grid screens seem out of date, or they aren't updating correctly, you can reset the entire system on
        your
        computer. To do this, you will first have to log out of every single browser window using this application.
    </p>
    <p>Once you have closed <b>ALL</b> of the other windows, click the "Reset Data" button below and the process will begin.
    </p>
    <p>When you log back in again, a fresh copy of the data will be downloaded to your machine. It could take a couple
        minutes to do this. If the loading window seems to get stuck or stops, it is just because your machine may
        be running low on memory while it does this.
    </p>
    <p>
        You can click "F5" to refresh if this happens for more than
        a couple minutes.
    </p>
</mat-dialog-content>

<mat-dialog-actions >
    <button mat-flat-button color="warn" slot="right" class="mat-raised-button" (click)="clearIndexDB()">Reset Data</button>
    <button mat-stroked-button slot="right" class="mat-raised-button" [mat-dialog-close]="true">Cancel</button>

</mat-dialog-actions>