export const releaseInfo =
 {
 	branch: "beta",
 	lastCommit: "11/07/2024 13:38:59",
 	lastTag: "",
 	buildType: "local",
 	company: "Pinnacle Power Services",
 	user: "dmorr",
 	compileTime: "11/12/2024 17:05:25"
 }
