
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http';

import { AuthService } from './auth/auth.service';
// import * as K from '../helpers/constants';
import { environment } from '../../environments/environment';
import { map, share, catchError } from 'rxjs/operators';

@Injectable()
export class UserService {
  apiURL = 'api/User';
  sessionId: string;
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.sessionId = authService.currentUser ? authService.currentUser.SessionId : null;
  }

  getOne(id): Observable<any> {
    return this.http.get(`${environment.serverURL}/${this.apiURL}/${id}?sid=${this.sessionId}`)
      .pipe(
        map((res: Response) => {
       const data = res.json();
       return data[0];
      }),
      share(),
      catchError(this.handleError));
  }
  getAll() {
    return this.http.get(`${environment.serverURL}/${this.apiURL}?sid=${this.sessionId}`)
    .pipe(map((res: Response) => {
      let users: any = res.json();
      return users.map(user => {
        user.FullName = user.FirstName + ' ' + user.LastName;
        return user;
      });
    }),
    share()
    ,catchError(this.handleError));
  }
  // private buildURL(urlPart) {
  //   return `${this.apiURL}/${urlPart}?sid=${this.sessionId}`;
  // }
  private extractData(res: Response) {
    let body = res.json();
    return body || [];
  }
  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      if (error.status < 200 || error.status >= 300) {
        errMsg = 'This request has failed ' + error.status + ' - ' + error.statusText;
      } else {
        errMsg = `${error.status} - ${error.statusText || ''}`;
      }
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return observableThrowError(errMsg || 'Server Error');
  }
}