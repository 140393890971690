import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { ChartModel } from 'app/models/ChartModel';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  constructor() { }

  public data: ChartModel[];
 
  private hubConnection: signalR.HubConnection
   
    public startConnection = () => {
      this.hubConnection = new signalR.HubConnectionBuilder()
                              .withUrl('wss://localhost:50609/chat')
                              .build();
   
      this.hubConnection
        .start()
        .then(() => console.log('Connection started'))
        .catch(err => console.log('Error while starting connection: ' + err))
    }
   
    public addTransferChartDataListener = () => {
      this.hubConnection.on('transferchartdata', (data) => {
        this.data = data;
        console.log(data);
      });
    }
}
