import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataStoreService } from './datastore.service';
import { IdbService } from './idb.service';
import { environment } from 'environments/environment';
import { DatalayerService } from './datalayer.service';
import { AuthService } from './auth/auth.service';
export const apiServer = environment.siteReviewURL;


@Injectable()
export class AppConfigService {

  public version: string;
  public apiEndpointSomeData: string;
  public apiServer: string = apiServer;
  constructor(private idb: IdbService, private dataStore: DataStoreService, private auth: AuthService) { }

  async load(): Promise<any> {


    return new Promise(async (resolve, reject) => {
      if (!this.auth.sessionId) resolve({});
      var fm = await this.dataStore.getForemen().toPromise();
      var gm = await this.dataStore.getGeneralForemen().toPromise();
      var sup = await this.dataStore.getAllCrewSupervisors();
      resolve({})
    }
    );


  }
}