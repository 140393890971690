import { Component, Input, OnInit } from '@angular/core';
 
@Component({ 
  selector: 'app-iframe',
  template: '<iframe [src]="mapUrl | safe"   width="100%" frameBorder="0" style="overflow-y: hidden;height: calc(100% - 115px);"></iframe>'
})
export class FrameComponent  implements OnInit  {
  @Input() src:string;
  mapUrl ;
  constructor( ) {  }   
   
  ngOnInit() {    this.mapUrl=this.src;  }
}
