<button mat-icon-button class="closer" [mat-dialog-close]="">
  <mat-icon mat-list-icon>close</mat-icon>
</button>
 
 <div ng2FileDrop *ngIf="!spinner" 
    [uploader]="uploader"
    class="drop-zone">

  <mat-icon  aria-label="Upload files from your computer">cloud_upload</mat-icon>&nbsp;Drop a file/s
 
  <div fxLayout="row" fxLayoutWrap="wrap" *ngIf="uploader?.queue[0]?.progress">
    <div fxFlex="100" *ngIf="uploader?.queue[0]?.progress!=100 ">
      <div>Uploading progress: {{ uploader?.queue[0]?.progress }}%</div>
    </div>
    <div fxFlex="100" *ngIf="uploader?.queue[0]?.progress==100 ">
      <div>Scanning Sheet and Importing Records... 
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin: auto; display: block;" width="60px" height="60px" viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid">
          <g transform="rotate(0 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s"
                repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(30 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s"
                repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(60 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite">
              </animate>
            </rect>
          </g>
          <g transform="rotate(90 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s"
                repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(120 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s"
                repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(150 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite">
              </animate>
            </rect>
          </g>
          <g transform="rotate(180 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s"
                repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(210 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s"
                repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(240 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite">
              </animate>
            </rect>
          </g>
          <g transform="rotate(270 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s"
                repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(300 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s"
                repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(330 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite">
              </animate>
            </rect>
          </g>
        </svg>
      </div>
    </div>
  </div>
  <div class="browse-file" (click)="fileUploaderInput.click()">(Click here to select a file/s)</div>
  <input #fileUploaderInput type="file" (change)="onFileChanged($event)" ng2FileSelect multiple [uploader]="uploader" style="display: none;"/>
</div>
 
<div style="width:100%;margin-top:100px;text-align: center;" *ngIf="spinner"   >
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
  style="margin: auto; display: block;" width="60px" height="60px" viewBox="0 0 100 100"
  preserveAspectRatio="xMidYMid">
  <g transform="rotate(0 50 50)">
    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(30 50 50)">
    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(60 50 50)">
    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite">
      </animate>
    </rect>
  </g>
  <g transform="rotate(90 50 50)">
    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(120 50 50)">
    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(150 50 50)">
    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite">
      </animate>
    </rect>
  </g>
  <g transform="rotate(180 50 50)">
    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(210 50 50)">
    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(240 50 50)">
    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite">
      </animate>
    </rect>
  </g>
  <g transform="rotate(270 50 50)">
    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(300 50 50)">
    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s"
        repeatCount="indefinite"></animate>
    </rect>
  </g>
  <g transform="rotate(330 50 50)">
    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#1d3f72">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite">
      </animate>
    </rect>
  </g>
</svg>
  <br><br>
  <h3>Analyzing PDF File</h3>
</div>

