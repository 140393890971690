<mat-card class="p-0">
  <mat-card-title class="">
    <div class="card-title-text">File upload</div>
    <mat-divider></mat-divider>
  </mat-card-title>
  <mat-card-content>
      <div class="mb-1">
        <p>Multiple</p>
        <input type="file" ng2FileSelect [uploader]="uploader" multiple  /><br/>

        <p>Single</p>
        <input type="file" ng2FileSelect [uploader]="uploader" />
      </div>

      <div class="mb-1">
        <div ng2FileDrop
        [ngClass]="{'dz-file-over': hasBaseDropZoneOver}"
        (fileOver)="fileOverBase($event)"
        [uploader]="uploader"
        class="fileupload-drop-zone">
        Drop file here
        </div>
      </div>

      <table class="default-table mat-box-shadow" style="width: 100%">
        <thead>
        <tr>
            <th width="30%">Name</th>
            <th>Size</th>
            <th>Progress</th>
            <th>Status</th>
            <th>Actions</th>
        </tr>
        </thead>
        <tbody *ngIf="uploader.queue.length; else tableNoData">
        <tr *ngFor="let item of uploader.queue">
            <td>{{ item?.file?.name }}</td>
            <td nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
            <td>
                <div class="progress" style="margin-bottom: 0;">
                    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
                    <mat-progress-bar
                      class=""
                      color="primary"
                      mode="determinate"
                      [value]="item.progress">
                    </mat-progress-bar>
                </div>
            </td>
            <td class="">
                <mat-icon *ngIf="item.isSuccess">check</mat-icon>
                <mat-icon *ngIf="item.isCancel">cancel</mat-icon>
                <mat-icon *ngIf="item.isError">error</mat-icon>
            </td>
            <td nowrap>
                <button 
                mat-raised-button
                class="mat-primary"
                (click)="item.upload()"
                [disabled]="item.isReady || item.isUploading || item.isSuccess">Upload</button>
                <button 
                mat-raised-button
                class="mat-accent"
                (click)="item.cancel()"
                [disabled]="!item.isUploading">Cancel</button>

                <button 
                mat-raised-button
                class="mat-warn"
                (click)="item.remove()">Remove</button>
            </td>
        </tr>
        </tbody>
        <ng-template #tableNoData>
          <p [ngStyle]="{padding: '0 1.2rem'}">Queue is empty</p>
        </ng-template>
    </table>


      <div>
        <p>Queue progress:</p>
        <div class="progress mb-1" >
            <mat-progress-bar
              class=""
              color="primary"
              mode="determinate"
              [value]="uploader.progress">
            </mat-progress-bar>
        </div>

      <button 
      mat-raised-button
      class="mat-primary"
      (click)="uploader.uploadAll()"
      [disabled]="!uploader.getNotUploadedItems().length">Upload all</button>

      <button 
      mat-raised-button
      class="mat-accent"
      (click)="uploader.cancelAll()"
      [disabled]="!uploader.isUploading">Cancel all</button>

      <button 
      mat-raised-button
      class="mat-warn"
      (click)="uploader.clearQueue()"
      [disabled]="!uploader.queue.length">Remove all</button>
    </div>
  </mat-card-content>
</mat-card>
