
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { AuthService } from './auth/auth.service';
// import * as K from '../helpers/constants';
import { environment } from '../../environments/environment';
import { catchError, map, share } from 'rxjs/operators';

@Injectable()
export class JobService {
  apiURL = 'api/Job';
  sessionId: string;
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.sessionId = authService.currentUser ? authService.currentUser.SessionId : null;
  }

  getOne(id): Observable<any> {
    return this.http.get(`${environment.serverURL}/${this.apiURL}/${id}?sid=${this.sessionId}`)
      .pipe(
        map((res:any) => {
        const data = res.json();
        return data[0];
      })
      ,share()
      ,catchError(this.handleError));
    
  } 
  
  add(job) {

  }
  save(job) {
    if (job.JobID === 0) {
      return this.http.post(`${environment.serverURL}/${this.apiURL}?sid=${this.sessionId}`, job)
        .pipe(
          map((res:any) => {
          const data = res.json();
          return data[0];
        }),
        share(),
        catchError(this.handleError));
    }
    return this.http.put(`${environment.serverURL}/${this.apiURL}?sid=${this.sessionId}`, job)
      .pipe(
        map((res:any) => {
        const data = res.json();
        return data[0];
      })
      ,share()
      ,catchError(this.handleError));
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || [];
  }
  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      if (error.status < 200 || error.status >= 300) {
        errMsg = 'This request has failed ' + error.status + ' - ' + error.statusText;
      } else {
        errMsg = `${error.status} - ${error.statusText || ''}`;
      }
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return observableThrowError(errMsg || 'Server Error');
  }
}