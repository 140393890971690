import { NgModule } from '@angular/core';
import { RelativeTimePipe } from './common/relative-time.pipe';
import { ExcerptPipe } from "./common/excerpt.pipe";
import { KeysPipe } from './common/keys.pipe';
import { SafePipe } from './common/safe.pipe';
import { SanitizeHtmlPipe } from './common/sanitize-html.pipe';
import { BrPipe } from './common/br.pipe';
 @NgModule({
  declarations: [
    RelativeTimePipe,
    ExcerptPipe,
    KeysPipe,
    SafePipe,
    SanitizeHtmlPipe,
    BrPipe
  ],
  exports: [
    RelativeTimePipe,
    ExcerptPipe,
    KeysPipe,
    SafePipe,
    SanitizeHtmlPipe,
    BrPipe
  ]
})
export class CommonPipesModule { }