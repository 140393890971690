import { NgModule } from '@angular/core';

//these are deprecated and should be removed when can be certain they are completely unused
import { JobService } from './job.service';
import { UserService } from './user.service';
import { UsersResolve } from './users.resolve';

//this is current
import { CommonService } from './common.service';
@NgModule({
  providers: [JobService, UserService, UsersResolve, CommonService]
})
export class SharedServicesModule {}