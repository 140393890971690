import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CellHistoryRequest } from 'app/models/cell-history';
import { Command } from 'app/models/command';
import { FleetService } from 'app/views/equipment/fleet.service';

@Component({
  selector: 'app-history-dialog',
  templateUrl: './history-dialog.component.html',
  styleUrls: ['./history-dialog.component.css']
})
export class HistoryDialogComponent implements OnInit {
  history;
  results;
  constructor(private db: FleetService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: CellHistoryRequest,) {

      console.log('History Dialog',data)

  }


  ngOnInit(): void {
     this.retrieveHistory(this.data)
  }

  retrieveHistory(obj:CellHistoryRequest) {
   
    let cmd = new Command();
    cmd.procedure = "cmdCellHistory"
    cmd.addParameter("TableName", obj.table)
    cmd.addParameter("KeyColumn", obj.keyColumn)
    cmd.addParameter("KeyValue", obj.keyValue)
    cmd.addParameter("ColumnName", obj.columnName)

    this.db.command(cmd)
      .subscribe(retval => {
        this.results = retval;
       console.log('results', retval)

      });



  }
  btnCancel(){
    this.dialogRef.close();
  }
}
