
<mat-sidenav-container class="app-side-nav-container">
  <!-- Main side navigation -->
  <mat-sidenav #sidenav 
  [opened]="!isNavOver()" 
    [mode]="isNavOver() ? 'over' : 'side'" class="sidebar-panel">
    <div id="scroll-area" class="navigation-hold" fxLayout="column">
      <!-- App Logo -->
      <div class="branding default-bg">

          <!-- <img src="assets/images/logo.png" alt="" class="app-logo"> -->
          <!-- Two different logos for dark and light themes -->
          <!-- <img 
          src="assets/images/logo-text-white.png" 
          alt="" 
          class="app-logo-text"
          *ngIf="themeService.activatedThemeName.indexOf('dark') !== -1"> -->
          <!-- <img 
          src="assets/images/logo3.png" 
          alt="" 
          width="210px"
          *ngIf="themeService.activatedThemeName.indexOf('dark') === -1"> -->
          <img 
          [src]="logoUrl" 
          alt="" 
          width="210px"
          >
      </div>

       
      <!-- Sidebar user -->
      <div class="app-user" style="display:none">
        <!-- <div class="app-user-photo">
          <img [src]="User.photoUrl" alt="">
        </div> -->

        <span class="app-user-name mb-05">
            <button 
            mat-icon-button 
            md-xs-button
            [matMenuTriggerFor]="appUserMenu">
              <mat-icon class="text-muted">settings</mat-icon>
              <span style="font-weight: normal">{{User?.FirstName}} {{User?.LastName}}</span>
            </button>
        </span>
         <mat-menu #appUserMenu="matMenu">
            <button mat-menu-item routerLink="/profile/overview">
              <mat-icon>account_box</mat-icon>
              <span>Profile</span>
            </button>
            <button mat-menu-item routerLink="/profile/settings">
              <mat-icon>settings</mat-icon>
              <span>Account Settings</span>
            </button>
           
            <button mat-menu-item routerLink="/sessions/signin">
              <mat-icon>exit_to_app</mat-icon>
              <span>Sign out</span>
            </button>
          </mat-menu>


        <!-- Small buttons -->
        <div class="app-user-controls">
          
          <!-- <button 
          class="text-muted"
          mat-icon-button 
          md-xs-button
          matTooltip="Inbox"
          routerLink="/inbox">
            <mat-icon>email</mat-icon>
          </button>
          <button 
          class="text-muted"
          mat-icon-button 
          md-xs-button
          matTooltip="Sign Out"
          routerLink="/sessions/signin">
            <mat-icon>exit_to_app</mat-icon>
          </button>

           -->
         
        </div>
      </div>
      <!-- Navigation -->
      <navigation></navigation>
    </div>
  </mat-sidenav>

  <!-- Top Bar -->
  <!-- Template reference variables of Left sidebar and Right notification is supplied -->
  <!-- Listens language change event -->
  <topbar 
  [sidenav]="sidenav" 
  [notificPanel]="notificationPanel" ></topbar>



  <!-- App content -->
  <app-breadcrumb #breadcrumb></app-breadcrumb>
  <div class="rightside-content-hold">
    <div class="view-loader" *ngIf="isModuleLoading">
      <span class="k-icon k-i-loading" style="font-size: 64px; color: #ff6358"></span>
    </div>
    <router-outlet></router-outlet>
  </div>



  <!-- Notificaation bar -->
  <mat-sidenav #notificationPanel mode="over" class="" position="end">
    <div class="navigation-hold" fxLayout="column">
      <app-notifications [notificPanel]="notificationPanel"></app-notifications>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
