
declare var google: any

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-gauge',
  template: `<div #container ></div>`

})
export class GaugeComponent implements OnInit {
  @Input() config: any;

  private _data;

  @Input()
  set data(value: any) {
    this._data = value;
     this.loadChart();
  }
  get data() {
    return this._data;
  };

  @ViewChild('container') container: ElementRef;



  constructor() {     google.charts.load('current', { 'packages': ['gauge'] });
  }
  ngOnInit(): void {
    //have some overloads and defaults
     if(!this.data) this.data = [
      ['Label', 'Value'],
      ['Memory', 80],
      ['CPU', 55],
      ['Network', 68]
    ]

    if(!this.config)  this.config ={
      width: 400, height: 120,
      redFrom: 90, redTo: 100,
      yellowFrom:75, yellowTo: 90,
      minorTicks: 5
    }; 
 
 console.log('initation of gauge chart', this.data)
this.loadChart()

  }
  loadChart() {
    var callback = () => this.drawChart();
    google.charts.setOnLoadCallback(callback);
  }

  drawChart() {

     
    var chartFunc = this.BuildGaugeChartObject(this.config);
    this.buildChart(this.data, chartFunc, this.config);
  }
 

  public BuildGaugeChartObject(config: any): Function {
    var chartFunc = () => { return new google.visualization.Gauge(this.container.nativeElement); };
     
    return chartFunc;
  }

  public buildChart(data: any[], chartFunc: any, options: any): any {
    var func = (chartFunc, options) => {
      var data = google.visualization.arrayToDataTable(this.data);
      chartFunc().draw(data, options);
    };
    var callback = () => func(chartFunc, options);
    google.charts.setOnLoadCallback(callback);
  }
}
