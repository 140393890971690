import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment, Params } from '@angular/router';


import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { DatalayerService } from '../../../services/datalayer.service';
 
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  providers: [DatalayerService]
})
export class FileUploadComponent implements OnInit {

  public uploader: any
  public hasBaseDropZoneOver: boolean = false;
  public jobId: number;
  console = console;
  constructor(private dl: DatalayerService, private activatedRoute: ActivatedRoute) {


    this.activatedRoute.params.subscribe((params: Params) => {
      this.jobId = params['id'];

    });

    let url: string = dl.apiServer + dl.fileUploadUrl;
    let path: any = { name: "filePath", value: "webapi/jobs/" + this.jobId }
    let creds: any = { name: "SessionId", value: dl.sessionId }
    let headers:any = [];
    headers.push(path);
    headers.push(creds);
    console.log(url)
    this.uploader = new FileUploader({ url: url, headers: headers});
    let fo: FileUploaderOptions = <FileUploaderOptions>{};




  }

  ngOnInit() {
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
}
