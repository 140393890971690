
<div class="name-header">{{name}}</div>
<div class="msg-box">
    <div class="msg-scroller" id="scroller" >
        <div class="msg-row" *ngFor="let message of messages; let i = index">
          
            <p class="msg">{{message}}</p>
        </div>

        <!-- <div class="row" *ngIf="messages.length > 0">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Messages</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let message of messages; let i = index">
                            <td>{{i + 1}}</td>
                            <td>{{message}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->
        <div class="row" *ngIf="messages.length <= 0">
            <span>No messages</span>
        </div>
    </div>
</div>

<form class="form-inline" (ngSubmit)="sendMessage()" #messageForm="ngForm">
    <div style="width:100%">
       

        <input type="text" style="width:100%;height:50px" class="form-control" id="message"
            placeholder="your message..." name="message" [(ngModel)]="message" required>

        <!--  
                <button mat-icon-button type="submit" style="float:right;" [disabled]="!messageForm.valid">
                    <mat-icon>mail</mat-icon>
                </button>
          -->
         
         

    </div>

</form>